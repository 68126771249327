import React, {
	FunctionComponent,
	useState,
	useContext,
	Dispatch,
} from "react";
import {
	CardElement,
	injectStripe,
	ReactStripeElements,
} from "react-stripe-elements";
import { CartContext } from "../cart/CartProvider";
import Stripe from "stripe";
import { Price } from "../Price";
import {
	Card,
	CardTitle,
	CardText,
	TextField,
	Button,
	CardActions,
	DialogContainer,
} from "react-md";
import { ServiceFee } from "./ServiceFee";

export enum AsyncStatus {
	EMPTY,
	LOADING,
	LOADED,
	ERROR,
}

interface WrappedCheckoutFormProps
	extends ReactStripeElements.InjectedStripeProps {
	isCheckedOut: boolean;
	setIsCheckedOut: Dispatch<boolean>;
}

export const WrappedCheckoutForm: FunctionComponent<
	WrappedCheckoutFormProps
> = ({ stripe, isCheckedOut, setIsCheckedOut }) => {
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [stripeElemComplete, setStripeElemComplete] = useState(false);
	const [serviceFeeAck, setServiceFeeAck] = useState(false);
	const [stripeOrder, setStripeOrder] = useState<Stripe.orders.IOrder | null>(
		null
	);
	const [dialogText, setDialogText] = useState("");
	const [asyncStatus, setAsyncStatus] = useState<AsyncStatus>(
		AsyncStatus.EMPTY
	);
	const { items: cartItems, cart } = useContext(CartContext);

	const emailIsValid = email.match(/^\S+@\S+\.\S+/);
	const formIsValid =
		name.length > 0 && emailIsValid && stripeElemComplete && serviceFeeAck;
	const isLoading = asyncStatus === AsyncStatus.LOADING;

	const dialogActions = [
		<Button raised primary onClick={() => setDialogText("")}>
			OK
		</Button>,
	];

	async function onFormSubmit(e) {
		e.preventDefault();
		if (!formIsValid) {
			setDialogText("Please make sure to fill in all the fields.");
			return;
		}
		const cartData = cartItems.map(({ item, quantity }) => ({
			skuNo: item.id,
			quantity,
		}));
		if (asyncStatus !== AsyncStatus.LOADING) {
			setAsyncStatus(AsyncStatus.LOADING);
			try {
				const { token } = await stripe.createToken({
					name,
				});
				const result: {
					status: "OK" | "ERROR";
					msg: string;
					order: Stripe.orders.IOrder;
					errorType?: string;
					stripeData?: {
						message: string;
						type: string;
					};
				} = await fetch("/.netlify/functions/guest-checkout", {
					method: "POST",
					headers: {
						Accept: "application/json",
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ name, email, token, cartData }),
				}).then(response => response.json());
				if (result.status === "OK") {
					setStripeOrder(result.order);
					setAsyncStatus(AsyncStatus.LOADED);
					setIsCheckedOut(true);
					cart.reset();
				} else {
					setAsyncStatus(AsyncStatus.ERROR);
					if (result.stripeData && result.stripeData.message) {
						setDialogText(result.stripeData.message);
					}
				}
			} catch (error) {
				setAsyncStatus(AsyncStatus.ERROR);
			}
		}
	}
	return (
		<Card>
			{stripeOrder ? (
				<>
					<CardTitle title="Thank you for your payment" />
					<CardText>
						<ul>
							{stripeOrder.items
								.filter(({ amount }) => amount > 0)
								.map(({ description, amount }, i) => (
									<li key={i}>
										<p>{description}</p>
										<p>
											<Price value={amount} />
										</p>
									</li>
								))}
						</ul>
						<p>
							Total: <Price value={stripeOrder.amount} />
						</p>
					</CardText>
				</>
			) : (
				<form onSubmit={e => onFormSubmit(e)}>
					<CardText>
						<TextField
							required
							name="Name"
							id="checkoutFormName"
							label="Name on card"
							errorText="Please enter your name."
							value={name}
							onChange={value => setName(value as string)}
						/>
						<TextField
							type="email"
							name="email"
							id="checkoutFormEmail"
							required
							label="Email"
							errorText="Please enter your email address."
							value={email}
							onChange={value => setEmail(value as string)}
						/>
						<CardElement
							onChange={({ complete }) => setStripeElemComplete(complete)}
						/>
					</CardText>
					<CardText>
						<ServiceFee
							checked={serviceFeeAck}
							subtotal={cartItems.reduce<number>(
								(accum, { item, quantity }) => accum + item.price * quantity,
								0
							)}
							onChange={checked => setServiceFeeAck(checked)}
						/>
					</CardText>
					<CardActions>
						<Button
							raised
							primary
							type="submit"
							disabled={!formIsValid || isLoading}
						>
							{isLoading ? "Submitting...." : "Checkout"}
						</Button>
					</CardActions>
				</form>
			)}
			<DialogContainer
				visible={dialogText.length > 0}
				id="checkout-messages-dialog"
				onHide={() => setDialogText("")}
				title="Alert"
				actions={dialogActions}
			>
				<p>{dialogText}</p>
			</DialogContainer>
		</Card>
	);
};

export const CheckoutForm = injectStripe(WrappedCheckoutForm);
