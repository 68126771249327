import { useState, useEffect } from "react";

let stripeInstance = null;

function setStripeInstance(setter) {
	if (stripeInstance) {
		setter(stripeInstance);
		return;
	}
	if (window.Stripe) {
		stripeInstance = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_API_KEY);
		setter(stripeInstance);
		return;
	}

	document.querySelector("#stripe-js").addEventListener("load", () => {
		stripeInstance = window.Stripe(process.env.GATSBY_STRIPE_PUBLIC_API_KEY);
		setter(stripeInstance);
	});
}

export function useStripe() {
	const [stripe, setStripe] = useState(stripeInstance);

	useEffect(() => {
		setStripeInstance(setStripe);
	}, []);
	return stripe;
}
