import React, { FunctionComponent, useState, useContext } from "react";
import { Link } from "gatsby";

import Layout from "../layout";
import SEO from "../components/seo";
import { CartContext } from "../components/cart/CartProvider";
import { priceFormatter, Price } from "../components/Price";
import { StripeProvider, Elements } from "react-stripe-elements";
import { useStripe } from "../hooks/useStripe";
import { CheckoutForm } from "../components/CheckoutForm/CheckoutForm";
import {
	Grid,
	Cell,
	Card,
	CardTitle,
	Button,
	CardActions,
	CardText,
} from "react-md";
import { DelayedRedirect } from "../components/common/DelayedRedirect";

const CartPageTemplate: FunctionComponent<{}> = () => {
	const stripe = useStripe();
	const [isCheckedOut, setIsCheckedOut] = useState<boolean>(false);
	const { items, cart } = useContext(CartContext);
	const showCheckout = items.length > 0 || isCheckedOut;
	return (
		<div>
			<>
				{items.length > 0 ? (
					<>
						<Grid>
							{items.map(({ item, quantity }) => (
								<Cell key={item.id}>
									<Card>
										<CardTitle
											title={item.product.name}
											subtitle={priceFormatter(item.price)}
										/>
										<CardText>
											<p>
												Price: <Price value={item.price} />
											</p>
											<p>Quantity: {quantity}</p>
											<p>
												SubTotal: <Price value={item.price * quantity} />
											</p>
										</CardText>
										<CardActions>
											<Button
												raised
												secondary
												onClick={() => cart.remove(item)}
											>
												Remove
											</Button>
										</CardActions>
									</Card>
								</Cell>
							))}
						</Grid>
					</>
				) : (
					isCheckedOut || (
						<Grid>
							<Cell>
								<Card>
									<CardTitle title="OOPS." />
									<CardText>
										<p>There is nothing in your cart.</p>
										<p>
											Redirecting you to the{" "}
											<Link to="/payments">Payments Page</Link>
											<DelayedRedirect
												to="/payments"
												from="/cart"
												noThrow
												delay={1000}
											/>
										</p>
									</CardText>
								</Card>
							</Cell>
						</Grid>
					)
				)}
			</>
			<>
				{showCheckout && (
					<StripeProvider stripe={stripe}>
						<Grid>
							<Cell>
								<Elements>
									<CheckoutForm
										isCheckedOut={isCheckedOut}
										setIsCheckedOut={setIsCheckedOut}
									/>
								</Elements>
							</Cell>
						</Grid>
					</StripeProvider>
				)}
			</>
		</div>
	);
};

const CartPage: FunctionComponent<{}> = () => {
	return (
		<Layout title="Cart">
			<SEO title="Cart" />
			<CartPageTemplate />
		</Layout>
	);
};

export default CartPage;
