import React, { FunctionComponent, useState, MouseEvent } from "react";
import { Price } from "../Price";
import FontIcon from "react-md/lib/FontIcons";
import { Button, DialogContainer, SelectionControl } from "react-md";
import { Link } from "gatsby";

import * as style from "./serviceFee.module.scss";

interface ServiceFeeProps {
	checked: boolean;
	subtotal: number;
	onChange: (e?: any) => void;
}

export const ServiceFee: FunctionComponent<ServiceFeeProps> = ({
	checked,
	subtotal,
	onChange,
}) => {
	const [showModal, setShowModal] = useState(false);
	const { processingFee } = _feesInclusive(subtotal);

	return (
		<div className={style.container}>
			<label className={style.label}>
				<SelectionControl
					type="checkbox"
					id="svcfeeackcheck"
					name="service_ack"
					checked={checked}
					onChange={onChange}
					className={style.checkBox}
				/>{" "}
				<p className={style.ackText}>
					I understand that I will be charged a service fee of:{" "}
					<Price value={Math.round(processingFee)} />
				</p>
			</label>
			{/*<Button
				icon
				onClick={e => setShowModal(true)}
				forceIconSize={18}
				forceIconFontSize={true}
			>
				<FontIcon forceSize={18} forceFontSize={true}>
					help
				</FontIcon>
			</Button>*/}
			<Button floating secondary mini onClick={e => setShowModal(true)}>
				live_help
			</Button>

			{showModal && (
				<DialogContainer
					visible={showModal}
					id="checkout-svcfee-dialog"
					title="Why the service fee?"
					initialFocus="svcfeemodalclose"
					actions={[
						<Button
							raised
							primary
							onClick={() => setShowModal(false)}
							id="svcfeemodalclose"
						>
							OK
						</Button>,
					]}
				>
					<p>
						The service fee goes to cover the fees charged by the credit card
						processor:{" "}
						<a href="https://stripe.com/pricing" target="_blank">
							Stripe
						</a>
					</p>
					<p>
						For more information, please check our <Link to="/faqs">FAQs</Link>
					</p>
				</DialogContainer>
			)}
		</div>
	);
};

function _getProcessingFee(amount) {
	return amount * 0.029 + 30;
}

function _feesInclusive(
	targetDonation: number
): {
	final: number;
	processingFee: number;
} {
	let processingFee: number = _getProcessingFee(targetDonation);
	let final: number = targetDonation;
	while (final - processingFee < targetDonation - 0.0001) {
		final += targetDonation - (final - processingFee);
		processingFee = _getProcessingFee(final);
	}
	return { final, processingFee };
}
