import React, { FunctionComponent, useEffect, useState } from "react";
import { Redirect, RouteComponentProps, RedirectProps } from "@reach/router";

export interface DelayedRedirectProps
	extends RouteComponentProps<RedirectProps<any>> {
	delay?: number;
}

export const DelayedRedirect: FunctionComponent<DelayedRedirectProps> = ({
	delay = 500,
	...redirectProps
}) => {
	const [timerComplete, setTimerComplete] = useState(false);
	useEffect(() => {
		const timeout = setTimeout(() => setTimerComplete(true), delay);
		return () => clearTimeout(timeout);
	});
	return timerComplete && <Redirect {...redirectProps} />;
};
